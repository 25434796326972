import React, {FC, lazy, useEffect, useState} from 'react'
import uitoolkit from '@zoom/videosdk-ui-toolkit'
import '@zoom/videosdk-ui-toolkit/dist/videosdk-ui-toolkit.css'
import {KJUR} from 'jsrsasign'
import axios from 'axios'
import {useParams, useSearchParams} from 'react-router-dom'
import {useAuth} from '../../auth'
import {API_URL} from '../../../core/_requests'
import Swal from 'sweetalert2'
import SocketComponent from './SocketComponent'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

interface Props {
  // sessionName: string
  // userName: string
  channelId: string
}

const MeetingVideoCall: FC<Props> = ({}) => {
  const {id} = useParams()
  const {currentUser, auth} = useAuth()
  let sessionContainer: HTMLDivElement | null = null
  const sdkKey = import.meta.env.VITE_APP_ZOOM_SDK_KEY
  const sdkSecret = import.meta.env.VITE_APP_ZOOM_SDK_SECRET

  const [searchParams, setSearchParams] = useSearchParams()
  const callVideoCallId = searchParams.get('videoCallId') ?? auth?.user?.videoCallId
  const callUserId = searchParams.get('userId') ?? auth?.user?.id
  const callChannelId = searchParams.get('channelId') ?? id

  const [inCall, setInCall] = useState(false)
  const [inlineCall, setInlineCall] = useState(false)
  const [channelId, setChannelId] = useState(id as string)
  const [userName, setUserName] = useState(auth?.user?.name)

  const [meeting, setMeeting] = useState<any>({})
  const [callObject, setCallObject] = useState<any>({})
  const [loading, setLoading] = useState(false)
  const [recordingLoading, setRecordingLoading] = useState(false)
  const [sessionJoinStatus, setSessionJoinStatus] = useState(
    auth?.user?.id ? 'Joining' : 'WaitingRoom'
  )

  const [socketConnections, setSocketConnections] = useState<any>([])

  const getCallObject = async () => {
    try {
      const response = await axios.get(`${API_URL}/meeting/${callChannelId}`)
      setCallObject(response.data.data)
    } catch (error) {
      console.error('getCallObject', error)
    }
  }

  // let callUserId = searchParams.get('userId') ?? auth?.user?.id ?? getRandomCallerId()

  useEffect(() => {
    return () => {
      sessionContainer && uitoolkit.closeSession(sessionContainer)
    }
  }, [])

  useEffect(() => {
    if (!auth?.user?.id) {
      // prompt user for name
      setSessionJoinStatus('WaitingRoom')
      Swal.fire({
        title: 'Enter your name',
        imageUrl: '/media/logos/dcsl_logo_new.png',
        imageWidth: 93,
        imageHeight: 50,
        customClass: {
          confirmButton: 'bg-primary rounded-2',
          denyButton: 'btn btn-danger',
          cancelButton: 'bg-gray rounded-2',
        },
        imageAlt: 'Custom image',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off',
        },
        showCancelButton: true,
        confirmButtonText: 'Request to Join',
        showLoaderOnConfirm: true,
        preConfirm: async (name) => {
          Swal.showLoading()
          config.userName = name
          setUserName(name)
          getVideoSDKJWT()
          // await new Promise((resolve) => setTimeout(resolve, 5000))
          // return axios
          //   .post(`${API_URL}/user`, {name})
          //   .then((response) => {
          //     console.log('swal preconf response', response)
          //   })
          //   .catch((error) => {
          //    Swal.showValidationMessage(`Request failed: ${error}`)
          //   })
        },
        allowOutsideClick: () => false,
      })
    }
  }, [])

  useEffect(() => {
    getCallObject()
  }, [])

  const config = {
    videoSDKJWT: '',
    sessionName: channelId ?? callChannelId,
    userName: auth?.user?.name || 'User',
    sessionPasscode: '123',
    features: ['preview', 'video', 'audio', 'share', 'chat', 'users', 'settings'],
    options: {init: {}, audio: {}, video: {}, share: {}},
    virtualBackground: {
      allowVirtualBackground: true,
      allowVirtualBackgroundUpload: true,
      virtualBackgrounds: [
        `${window.location.protocol}//${window.location.host}/media/misc/dcsl_econnect_zoom_bg_1_r.jpg`,
        `${window.location.protocol}//${window.location.host}/media/misc/dcsl_econnect_zoom_bg_1.jpg`,
        'https://images.unsplash.com/photo-1715490187538-30a365fa05bd?q=80&w=1945&auto=format&fit=crop',
      ],
    },
  }
  const role = 1

  console.log('config', config)

  useEffect(() => {
    if (sessionJoinStatus !== 'WaitingRoom') {
      console.log('getVideoSDKJWT sessionJoinStatus', sessionJoinStatus)
      getVideoSDKJWT()
    }
  }, [])

  // use the zoom vido sdk session update to start recording
  const startMeetingRecording = async () => {
    // const token = generateSignature(config.sessionName, role)
    setRecordingLoading(true)
    const options = {
      method: 'POST',
      url: `${API_URL}/zoom/object/${config.sessionName}/recording`,
      data: {
        action: 'start',
      },
    }

    try {
      const response = await axios.request(options)
      console.log('startMeetingRecording', response)
      await getCallObject()
    } catch (error) {
      console.error(error)
    } finally {
      setRecordingLoading(false)
    }
  }

  const stopMeetingRecording = async () => {
    setRecordingLoading(true)
    const options = {
      method: 'POST',
      url: `${API_URL}/zoom/object/${config.sessionName}/recording`,
      data: {
        action: 'stop',
      },
    }

    try {
      const response = await axios.request(options)
      console.log('startMeetingRecording', response)
      await new Promise((resolve) => setTimeout(resolve, 3000))
      await getCallObject()
    } catch (error) {
      console.error(error)
    } finally {
      setRecordingLoading(false)
    }
  }

  async function generateSignature(sessionName: string, role: number) {
    const token0 = await axios.post(`${API_URL}/zoom/jwt`, {
      sessionName,
      roleType: role,
    })
    // console.log('token0', token0.data.data)
    const token = token0.data.data

    return token
  }

  async function getVideoSDKJWT() {
    sessionContainer = document.getElementById('sessionContainer') as HTMLDivElement
    // document.getElementById('join-flow')!.style.display = 'none'

    const token = await generateSignature(config.sessionName, role)

    if (token) {
      //   console.log(token)
      config.videoSDKJWT = token
      joinSession()
    }
  }

  async function joinSession() {
    // console.log(config)
    // document.getElementById('sessionContainer')!.style.display = 'block'
    setSessionJoinStatus('Joining')
    // delaye by 1 second
    await new Promise((resolve) => setTimeout(resolve, 1000))
    sessionContainer = document.getElementById('sessionContainer') as HTMLDivElement

    if (sessionContainer) {
      console.log('config,userName', config, userName)
      config.userName = userName
      uitoolkit.joinSession(sessionContainer, config)
      uitoolkit.onSessionJoined(sessionJoined)

      sessionContainer && uitoolkit.onSessionClosed(sessionClosed)
    }
  }

  const sessionClosed = () => {
    console.log('session closed')
    sessionContainer && uitoolkit.closeSession(sessionContainer)
    // document.getElementById('join-flow')!.style.display = 'block'
    // document.getElementById('sessionContainer')!.style.display = 'none'
    setSessionJoinStatus('NotJoined')
  }

  const sessionJoined = () => {
    console.log('session joined')
    setSessionJoinStatus('Joined')
  }

  const handleJoinRequestResponse = async (response: any) => {
    console.log('handleJoinRequestResponse', response)
    if (response.action === 'approve-join-request') {
      await getVideoSDKJWT()
    } else {
      // display rejected message
      Swal.fire({
        title: 'Host Rejected',
        text: 'The host has rejected your request to join the meeting',
        icon: 'error',
        confirmButtonText: 'Ok',
      })
    }
  }

  const handleUpdateUserList = async (users: any[]) => {
    console.log('handleUpdateUserList')
    setSocketConnections(users)
  }
  const handleRemovedFromSession = async () => {
    console.log('handleLeaveSession')
    sessionClosed()
    Swal.fire({
      title: 'Removed from Session',
      text: 'You have been removed from the meeting',
      icon: 'error',
      confirmButtonText: 'Ok',
    })
  }

  return (
    <div className='d-flex justify-content-center h-100  row' style={{}}>
      {sessionJoinStatus === 'NotJoined' ? (
        <div id='join-flow' className=' my-20 text-center'>
          <div className='mb-5'>
            <img src='/media/logos/dcsl_logo_new.png' alt='zoom' className='h-50px' />
          </div>
          <button className='btn btn-primary' onClick={() => getVideoSDKJWT()}>
            Join Meeting
          </button>
        </div>
      ) : null}

      {['Joined', 'Joining'].includes(sessionJoinStatus) ? (
        <div
          className={` mw-1000px w-100 px-11 py-10 col-12 col-lg-8 ${
            sessionJoinStatus !== 'WaitingRoom' ? 'd-block' : 'd-none'
          }`}
          id='sessionContainer'
          style={{height: '100vh'}}
        ></div>
      ) : null}

      {['Joined', 'Joining', 'WaitingRoom'].includes(sessionJoinStatus) ? (
        <div
          className={`app-sidebar-secondary bg-gray-200i mw-lg-300px w-lg-250px w-100 my-3 rounded-3 col-12 col-lg-4 ${
            sessionJoinStatus === 'Joined' ? '' : 'd-none'
          }`}
        >
          <div
            className='d-flex flex-column gap-8 flew-grow-1 p-4 p-lg-6'
            id='kt_sidebar_secondary_wrapper'
          >
            <div className=''>
              <img
                alt='Logo'
                src='/media/logos/dcsl_logo_new.png'
                className='app-sidebar-logo-default d-block h-30px mb-5 theme-light-show'
              />
              <h2> {callObject?.title}</h2>
            </div>
            <div>
              {callObject?.recordingStatus === 'InProgress' ? (
                <>
                  <div className='mb-3'>
                    <span
                      className='badge badge-light-danger badge-outline fs-7 me-3'
                      onClick={async () => {}}
                    >
                      <i className={`fa fa-record-vinyl fs-4 me-1 fa-fade`}></i>
                      {'Recording in Progress'}
                    </span>
                  </div>
                  {auth?.user?.userRole !== 'Admina' && (
                    <button
                      className='border-default btn btn-outline btn-outline-default btn-sm fs-7 me-3 py-2'
                      onClick={async () => {
                        if (!recordingLoading) {
                          await stopMeetingRecording()
                        }
                      }}
                    >
                      {recordingLoading ? (
                        <div className='me-2 spinner-border spinner-border-sm' role='status'>
                          <span className='sr-only'>Loading...</span>
                        </div>
                      ) : null}
                      Stop Recording
                    </button>
                  )}
                </>
              ) : auth?.user?.userRole !== 'Admind' ? (
                <button
                  className='border-default btn btn-outline btn-outline-dark btn-sm fs-7 me-3 py-2'
                  disabled={loading || callObject?.recordingStatus === 'InProgress'}
                  onClick={async () => {
                    await startMeetingRecording()
                  }}
                >
                  <i className={`fa fa-record-vinyl fs-4 me-1`}></i>
                  {'Start Recording'}
                  {recordingLoading ? (
                    <div className='ms-2 spinner-border spinner-border-sm' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                  ) : null}
                </button>
              ) : (
                <></>
              )}
            </div>
            {userName && (
              <SocketComponent
                sessionId={channelId}
                userName={userName}
                joinRequestResponse={handleJoinRequestResponse}
                updateUserList={handleUpdateUserList}
                removeFromSession={() => handleRemovedFromSession()}
              />
            )}
          </div>
        </div>
      ) : null}

      {sessionJoinStatus === 'WaitingRoom' && userName ? (
        <div id='join-flow' className='py-20 mt-20 mb-20 text-center'>
          <div className='mb-5'>
            <img src='/media/logos/dcsl_logo_new.png' alt='zoom' className='h-45px' />
          </div>
          <h5>Waiting for the host to let you in</h5>
        </div>
      ) : null}
    </div>
  )
}

export default MeetingVideoCall
